import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Button } from "shards-react";
import { FiAlertTriangle, FiMail } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getAuthErrorDescription } from "selectors/user";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import AuthZeroActions from "actions/AuthZeroActions";
import Constants from "const/Constants";
import React, { PureComponent } from "react";
import UserActions from "actions/UserActions";
import classNames from "classnames";

const mapStateToProps = (state) => ({
  envVars: getEnvVars(state),
  textsData: getTextsData(state),
  errorDescription: getAuthErrorDescription(state)
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (...args) => dispatch(UserActions.verifyEmail(...args)),
  loginWithRedirect: (...args) => dispatch(AuthZeroActions.loginWithRedirect(...args)),
  logoutUser: () => dispatch(AuthZeroActions.logoutUser())
});

@connect(mapStateToProps, mapDispatchToProps)
class AuthHandler extends PureComponent {
  static VERIFY_EMAIL_ERROR_CODE = "verify_email";

  checkLoginTimeoutId = null;

  state = { disableVerifyEmailButton: false };

  get error() {
    const { errorDescription } = this.props;

    try {
      return JSON.parse(atob(errorDescription));
    } catch (error) {
      return { code: errorDescription };
    }
  }

  componentDidMount() {
    const { errorDescription, loginWithRedirect, envVars } = this.props;

    document.body.dataset.auth = "";
    if (errorDescription) {
      this.checkLoginTimeoutId = setTimeout(
        () => loginWithRedirect(),
        Constants.LOGIN_PAGE_REFRESH_TIMEOUT
      );
    } else loginWithRedirect(envVars);
  }

  componentWillUnmount() {
    clearInterval(this.checkLoginTimeoutId);
    delete document.body.dataset.auth;
  }

  @bind
  handleLogoutButtonClick() {
    this.props.logoutUser();
  }

  @bind
  handleVerifyEmailButtonClick() {
    this.props.verifyEmail(this.error.payload.userId);
    this.setState({ disableVerifyEmailButton: true });
  }

  render() {
    const { textsData: { uiTexts, messages } } = this.props;

    const { disableVerifyEmailButton } = this.state;

    const { code: errorCode, payload: errorPayload } = this.error;

    const verifyEmailError = errorCode === AuthHandler.VERIFY_EMAIL_ERROR_CODE;

    return (
      <div className={classNames(Css.authHandler, CommonCss.flexCenter)}>
        <div>
          {errorCode
            ? <>
              <FiAlertTriangle className={Css.warningIcon} />
              <h4>{verifyEmailError ? messages.verifyEmail : errorCode}</h4>
              <div className={Css.buttonContainer}>
                {verifyEmailError && errorPayload && <Button
                  disabled={disableVerifyEmailButton}
                  onClick={this.handleVerifyEmailButtonClick}>
                  <FiMail />
                  <span>{uiTexts.resendVerificationEmail}</span>
                </Button>}
                <Button outline theme="secondary" onClick={this.handleLogoutButtonClick}>
                  {uiTexts.logout}
                </Button>
              </div>
            </>
            : <h4>{uiTexts.redirectingPleaseWait}</h4>}
        </div>
      </div>
    );
  }
}

export default AuthHandler;
