import "lib/index.scss";

import Css from "./style.module.scss";

import GlobalTooltip from "nlib/common/GlobalTooltip";
import ModalsContainer from "./lib/ModalsContainer";
import React, { Suspense } from "react";
import Routes from "./lib/Routes";

const App = () => {
  return (
    <>
      <div
        className={`${Css.app} app`}>
        <Routes />
        <Suspense fallback={null}>
          <ModalsContainer />
        </Suspense>
      </div>
      <GlobalTooltip />
    </>
  );
};

export default React.memo(App);
