import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import { Button, Col, FormGroup, Row } from "shards-react";
import { FiActivity, FiClock, FiFolder, FiInfo, FiUser, FiX } from "react-icons/fi";
import { Form, GoogleDrivePicker, ModalWindow } from "lib/common";
import { checkOrganizationsFetching, getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "hooks";
import Constants from "const/Constants";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";

const GoogleDriveIcon = () => <img src={googleDrive} />;

const SetupBackupWindow = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const fetchingData = useSelector(checkOrganizationsFetching);

  const {
    backupInfo: {
      accountConnected = false,
      accountEmail = "",
      backupFolderId = "",
      backupFolderName = "",
      lastBackupAt = ""
    } = {}
  } = useSelector(getActiveOrganization);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleGoogleDriveSignIn = useCallback(async(authResult) => {
    if (authResult.email === accountEmail) {
      if (backupFolderId) {
        await dispatch(OrganizationsActions.setBackupInfo({ folderId: backupFolderId, folderName: backupFolderName }));
        await dispatch(OrganizationsActions.fetchOrganizationsList());
      }
    } else if (backupFolderId) {
      await dispatch(OrganizationsActions.deleteBackupInfo());
      await dispatch(OrganizationsActions.fetchOrganizationsList());
    }
  }, [accountEmail, backupFolderId, backupFolderName, dispatch]);

  const handleGoogleDrivePickerFilesPicked = useCallback(async(files) => {
    if (files.length) {
      const [{ id: folderId, name: folderName }] = files;

      await dispatch(OrganizationsActions.setBackupInfo({ folderId, folderName }));
      await dispatch(OrganizationsActions.fetchOrganizationsList());
    }
  }, [dispatch]);

  const handleDisconnectButtonClick = useCallback(async() => {
    await dispatch(OrganizationsActions.deleteBackupInfo());
    await dispatch(OrganizationsActions.fetchOrganizationsList());
    onClose();
  }, [onClose, dispatch]);

  useInterval(
    () => { dispatch(OrganizationsActions.fetchOrganizationsList(true)); },
    Constants.DATA_LIST_UPDATE_INTERVAL
  );

  useEffect(() => {
    dispatch(OrganizationsActions.fetchOrganizationsList());
  }, [dispatch]);

  return (
    <ModalWindow
      className={classNames(Css.setupBackupWindow, accountConnected && Css.accountConnected)}
      config={{
        headerText: uiTexts.setupGoogleDriveBackup
      }}
      disabledButtons={fetchingData}
      iconComponent={GoogleDriveIcon}
      onClose={handleClose}>
      <Form>
        <FormGroup row>
          <Row form>
            <Col>
              <div>
                <FiInfo />
                <span>{messages.setupBackupWindowDescription}</span>
              </div>
            </Col>
          </Row>
        </FormGroup>
        {accountEmail && <FormGroup row>
          <div className={Css.connectionInfo}>
            <div>
              <FiActivity />
              <span>
                <b>{`${uiTexts.connectionStatus}: `}</b>
                <span className={accountConnected ? CommonCss.positiveText : CommonCss.negativeText}>
                  {(accountConnected ? uiTexts.connected : uiTexts.disconnected).toUpperCase()}
                </span>
              </span>
            </div>
            <div>
              <FiUser />
              <span><b>{`${uiTexts.connectedAccount}: `}</b><span title={accountEmail}>{accountEmail}</span></span>
            </div>
            <div>
              <FiFolder />
              <span><b>{`${uiTexts.connectedFolder}: `}</b><span title={backupFolderName}>{backupFolderName}</span></span>
            </div>
            <div>
              <FiClock />
              <span>
                <b>{`${uiTexts.lastBackup}: `}</b>
                <span>
                  {lastBackupAt
                    ? moment.duration(moment.utc(lastBackupAt).diff(moment.utc())).humanize(true)
                    : uiTexts.inProgress}
                </span>
              </span>
            </div>
          </div>
        </FormGroup>}
        <FormGroup row>
          <Row form>
            <Col className={Css.buttonsContainer}>
              <GoogleDrivePicker
                offlineAccess
                onlyFolders
                onlyOneSelection
                onlySignIn={accountEmail && !accountConnected}
                className={Css.googleDrivePicker}
                text={accountEmail
                  ? (accountConnected ? uiTexts.changeFolder : uiTexts.signInWithGoogle)
                  : uiTexts.selectGoogleDriveFolder}
                disabled={fetchingData}
                onSignIn={handleGoogleDriveSignIn}
                onFilesPicked={handleGoogleDrivePickerFilesPicked} />
              {accountConnected && <Button
                size="sm"
                theme="danger"
                disabled={fetchingData}
                onClick={handleDisconnectButtonClick}>
                <span>
                  <FiX />
                  <span>{uiTexts.disconnect}</span>
                </span>
              </Button>}
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default SetupBackupWindow;
