import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiTag } from "react-icons/fi";
import { Form, ModalWindow, TagsInput } from "lib/common";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";

const AddTagsWindow = ({ autoCompleteData, onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [tags, setTags] = useState([]);

  const handleClose = useCallback((result) => {
    onClose(result ? tags : result);
  }, [tags, onClose]);

  const handleTagsInputChange = useCallback(({ target: { value } }) => {
    setTags(value || []);
  }, []);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.addTagsWindow}
      config={{
        confirm: true,
        headerText: uiTexts.addTags,
        okButtonText: uiTexts.add
      }}
      disabledOkButton={!tags.length}
      iconComponent={FiTag}
      onClose={handleClose}>
      <Form>
        <FormGroup row className={CommonCss.flexCenter}>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span>{uiTexts.tags}</span>
                  </InputGroupText>
                </InputGroupAddon>
                <TagsInput
                  autoCompleteData={autoCompleteData}
                  placeholder={uiTexts.addTags}
                  value={tags}
                  onChange={handleTagsInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(AddTagsWindow);
