import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiUser } from "react-icons/fi";
import { Form, ModalWindow, SelectContactInput } from "lib/common";
import { getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import ContactsActions from "actions/ContactsActions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";

const LinkContactWindow = ({ fullContact = false, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsData);

  const [widgetShown, setWidgetShown] = useState(false);

  const [vendorId, setVendorId] = useState("");

  const [contactName, setContactName] = useState("");

  const [contact, setContact] = useState({});

  const inputName = useMemo(() => uuid(), []);

  const handleClose = useCallback((result) => {
    onClose(result && fullContact ? contact : vendorId);
  }, [contact, fullContact, onClose, vendorId]);

  const handleContactInputChange = useCallback(({ target: { value } }) => {
    setVendorId("");
    setContactName(value);
    setContact({});
  }, []);

  const handleContactInputAutoComplete = useCallback(({ target: { value } }, contactData) => {
    setContactName(value);

    const matchingContact = contactData || contactsData.find((vendor) => Utils.checkSimilarityBy({ name: value }, vendor, 1));

    if (matchingContact) {
      setVendorId(matchingContact.id);
      setContact(matchingContact);
    }
  }, [contactsData]);

  const handleContactInputContactOpen = useCallback(() => {
    setWidgetShown(true);
  }, []);

  useEffect(() => {
    dispatch(ContactsActions.fetchContactsList());
  }, [dispatch]);

  return (
    <ModalWindow
      className={Css.linkContactWindow}
      config={{
        confirm: true,
        headerText: uiTexts.linkContact,
        okButtonText: uiTexts.save
      }}
      disabledOkButton={!vendorId}
      iconComponent={FiUser}
      onClose={handleClose}>
      <Form>
        <FormGroup row className={CommonCss.flexCenter}>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span>{uiTexts.contact}</span>
                  </InputGroupText>
                </InputGroupAddon>
                <SelectContactInput
                  right
                  toggleButton
                  useWidget
                  name={inputName}
                  className={Css.selectContactInput}
                  vendorId={vendorId}
                  value={contactName}
                  widgetShown={widgetShown}
                  setWidgetShown={setWidgetShown}
                  onChange={handleContactInputChange}
                  onAutoComplete={handleContactInputAutoComplete}
                  onContactOpen={handleContactInputContactOpen} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default LinkContactWindow;
