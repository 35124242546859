import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AutoCompleteInput, Form, ModalWindow } from "lib/common";
import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiEdit } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { getTransactionsReasons } from "selectors/transactions";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import TransactionsActions from "actions/TransactionsActions";

const AddReasonWindow = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const transactionsReasons = useSelector(getTransactionsReasons);

  const [reason, setReason] = useState("");

  const modalWindowConfig = useMemo(() => ({
    confirm: true,
    headerText: uiTexts.addReason,
    okButtonText: uiTexts.add
  }), [uiTexts]);

  const autoCompleteOptions = useMemo(() => {
    return transactionsReasons.map(({ reason: transactionsReason }) => transactionsReason);
  }, [transactionsReasons]);

  const handleClose = useCallback((result) => {
    onClose(result ? reason : result);
  }, [reason, onClose]);

  const handleReasonInputChange = useCallback(({ target: { value } }) => {
    setReason(value);
    if (value.length >= Constants.SEARCH_TEXT_MIN_LENGTH) {
      dispatch(TransactionsActions.fetchTransactionsReasonsList([value]));
    }
  }, [dispatch]);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.addReasonWindow}
      config={modalWindowConfig}
      disabledOkButton={!(reason.trim() || "").length}
      iconComponent={FiEdit}
      onClose={handleClose}>
      <Form>
        <FormGroup row className={CommonCss.flexCenter}>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span>{uiTexts.reason}</span>
                  </InputGroupText>
                </InputGroupAddon>
                <AutoCompleteInput
                  noFocusHold
                  triggerLength={Constants.SEARCH_TEXT_MIN_LENGTH}
                  className={Css.reasonInput}
                  placeholder={uiTexts.describeThisTransactions}
                  value={reason}
                  invalid={!reason}
                  options={autoCompleteOptions}
                  onChange={handleReasonInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(AddReasonWindow);
