import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AvatarWithName, DropDownSelect } from "lib/common";
import { FiChevronDown, FiDollarSign, FiLogOut, FiSettings, FiStar, FiUser } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const SettingsDropDown = ({
  disabled,
  showActiveOrganizationName,
  onOrganizationSettingsOpen,
  onBillingOpen,
  onUserSettingsOpen,
  onLogout
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const userRestrictions = useSelector(getUserRestrictions);

  const activeOrganization = useSelector(getActiveOrganization);

  const { fullName, superAdmin, role, sub, picture } = useSelector(getUserData);

  const roleName = useMemo(() => {
    const organizationCreator = activeOrganization.creatorId === sub;

    return (organizationCreator
      ? uiTexts.organizationOwner
      : (uiTexts[UserRoles.getLangId(role)] || role || "")).toLowerCase();
  }, [uiTexts, activeOrganization, role, sub]);

  const toggleContent = useMemo(() => {
    return (
      <>
        <AvatarWithName
          left
          avatarUrl={picture}
          name={(
            <span className={Css.userName} data-superadmin={superAdmin ? "" : undefined}>
              {superAdmin && <FiStar />}
              <span>{`${fullName}${roleName ? ` (${roleName})` : ""}`}</span>
            </span>
          )}>
          {showActiveOrganizationName && (
            <div
              className={Css.organization}
              data-superaccess={activeOrganization.superAdminAccess ? "" : undefined}>
              <img src={Utils.getFlagIconUrl(activeOrganization.countryCode)} />
              <span>{activeOrganization.name}</span>
            </div>
          )}
        </AvatarWithName>
        <FiChevronDown className={Css.arrowIcon} />
      </>
    );
  }, [showActiveOrganizationName, fullName, superAdmin, roleName, picture, activeOrganization]);

  const dropDownOptions = useMemo(() => {
    const result = [
      {
        unselectable: true,
        value: "userSettings",
        label: <span>
          <FiUser />
          <span>{uiTexts.userProfile}</span>
        </span>
      },
      {
        unselectable: true,
        value: "organizationSettings",
        label: <span>
          <FiSettings />
          <span>{uiTexts.organizationSettings}</span>
        </span>
      },
      !activeOrganization.customBilling && !userRestrictions.usageStatsRead && {
        unselectable: true,
        value: "billing",
        label: <span>
          <FiDollarSign />
          <span>
            {activeOrganization.billingId && activeOrganization.subscriptionId ? uiTexts.billing : uiTexts.setupBilling}
          </span>
        </span>
      },
      {
        unselectable: true,
        value: "logout",
        label: <span>
          <FiLogOut />
          <span>{uiTexts.logout}</span>
        </span>
      }
    ];

    return result.filter(Boolean);
  }, [uiTexts, userRestrictions, activeOrganization]);

  const handleItemSelect = useCallback((value) => {
    const callbacks = {
      userSettings: onUserSettingsOpen,
      organizationSettings: onOrganizationSettingsOpen,
      billing: onBillingOpen,
      logout: onLogout
    };

    callbacks[value]();
  }, [onUserSettingsOpen, onOrganizationSettingsOpen, onBillingOpen, onLogout]);

  return (
    <DropDownSelect
      right
      className={Css.settingsDropDown}
      toggleClassName={Css.dropDownToggle}
      menuClassName={classNames(Css.dropDownMenu, CommonCss.zIndexB)}
      toggleContent={toggleContent}
      options={dropDownOptions}
      disabled={disabled}
      onChange={handleItemSelect} />
  );
};

export default React.memo(SettingsDropDown);
