import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, Badge, Table } from "lib/common";
import { FiAlertCircle, FiStar, FiUser } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useMemo } from "react";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import moment from "moment";

const ROLE_CELL_WIDTH = 140;

const FULL_NAME_CELL_WIDTH = 180;

const LAST_ACTIVITY_CELL_WIDTH = 100;

const NOTIFICATIONS_CELL_WIDTH = 110;

const ACTIONS_CELL_WIDTH = 80;

const UsersTable = ({
  data,
  className,
  disabled,
  userData,
  userRestrictions,
  guestsOnly,
  superAdminOrganizationAccess,
  onEditUser,
  onRevokeUser
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessesData = useSelector(getAllBusinessesData);

  const getTrProps = useCallback((state, { original: { email } }) => {
    if (userData.email === email) return { [superAdminOrganizationAccess ? "data-warning" : "data-highlight"]: "" };

    return {};
  }, [userData, superAdminOrganizationAccess]);

  const getTdProps = useCallback((state, { original: { email, pendingInvite } }, { clickable }) => {
    if (!clickable || disabled || pendingInvite) return {};

    return {
      style: { cursor: "pointer" },
      onClick: () => { onEditUser(email); }
    };
  }, [disabled, onEditUser]);

  const tableData = useMemo(() => {
    return (userRestrictions.usersRead ? [userData] : data)
      .map((user) => {
        return { ...user, notifications: user.emailNotifications || user.smsNotifications || user.pushNotifications };
      });
  }, [userData, userRestrictions, data]);

  const tableColumns = useMemo(() => [
    {
      accessor: "role",
      clickable: true,
      width: ROLE_CELL_WIDTH,
      Header: uiTexts.role,
      Cell: ({ value, original: { sub, email, guestUser } }) => {
        const organizationCreator = activeOrganization.creatorId === sub;

        const currentUser = userData.email === email;

        let content = value && uiTexts[UserRoles.getLangId(value)];

        if (currentUser && superAdminOrganizationAccess) content = "God mode: ON :)";
        if (organizationCreator) content = uiTexts.organizationOwner;
        if (guestUser) content = uiTexts.guestAccess;

        return (
          <span title={content || ""}>
            {content || Constants.EMPTY_PLACEHOLDER}
          </span>
        );
      }
    },
    {
      accessor: "fullName",
      clickable: !guestsOnly,
      width: FULL_NAME_CELL_WIDTH,
      Header: () => guestsOnly ? uiTexts.email : `${uiTexts.name}/${uiTexts.email}`,
      Cell: ({ value, original: { email, pendingInvite } }) => {
        const content = value || email;

        const currentUser = userData.email === email;

        return (
          <span title={content}>
            {pendingInvite
              ? <Badge value={uiTexts.pending} className={Css.pendingBadge} />
              : (currentUser && superAdminOrganizationAccess ? <FiStar /> : <FiUser />)}
            <span>
              {content === email
                ? <a className={CommonCss.darkText} href={`mailto:${content}`}>{content}</a>
                : <span>{content}</span>}
            </span>
          </span>
        );
      }
    },
    {
      accessor: "notifications",
      clickable: true,
      width: NOTIFICATIONS_CELL_WIDTH,
      Header: uiTexts.notifications,
      Cell: ({ value, original: { emailNotifications, smsNotifications, pushNotifications } }) => {
        return (
          <span className={value ? undefined : CommonCss.neutralText}>
            {value
              ? [emailNotifications && uiTexts.email, smsNotifications && uiTexts.sms, pushNotifications && uiTexts.push]
                .filter(Boolean).join(", ")
              : uiTexts.no}
          </span>
        );
      }
    },
    {
      accessor: "lastLoginAt",
      clickable: true,
      width: LAST_ACTIVITY_CELL_WIDTH,
      Header: uiTexts.recentActivity,
      Cell: ({ value }) => {
        const content = value
          ? moment.duration(moment.utc(value).diff(moment.utc())).humanize(true)
          : Constants.EMPTY_PLACEHOLDER;

        return <span title={value && content}>{content}</span>;
      }
    },
    {
      accessor: "businessIds",
      clickable: true,
      Header: uiTexts.businesses,
      sortMethod: (businessIdsA = [], businessIdB = []) => {
        const businessNamesA = businessIdsA
          .map((businessId) => (businessesData.find(({ id }) => id === businessId) || {}).name || "")
          .filter(Boolean)
          .sort()
          .join();

        const businessNamesB = businessIdB
          .map((businessId) => (businessesData.find(({ id }) => id === businessId) || {}).name || "")
          .filter(Boolean)
          .sort()
          .join();

        return businessNamesA.localeCompare(businessNamesB);
      },
      Cell: ({ value: businessIds = [] }) => {
        if (!businessIds.length) return uiTexts.allAvailable;

        const businessNames = businessIds
          .map((businessId) => (businessesData.find(({ id }) => id === businessId) || {}).name || "")
          .filter(Boolean)
          .sort();

        if (!businessNames.length) return (
          <span>
            <FiAlertCircle className={CommonCss.negativeText} />
            <span>{uiTexts.noBusinesses}</span>
          </span>
        );

        return (
          <span title={businessNames.join(", ")} className={TableCss.tagsCell}>
            <span>{businessNames[0]}</span>
            {businessNames.length > 1 && <span data-rest>{`...+${businessNames.length - 1}`}</span>}
          </span>
        );
      }
    },
    {
      width: ACTIONS_CELL_WIDTH,
      sortable: false,
      className: CommonCss.flexEnd,
      Header: uiTexts.actions,
      Cell: ({ original: { sub, email, pendingInvite } }) => {
        const currentUser = userData.email === email;

        const organizationCreator = activeOrganization.creatorId === sub;

        return (
          <ActionsButtons
            itemId={email}
            disabled={disabled}
            disabledEdit={pendingInvite || (currentUser && superAdminOrganizationAccess)}
            disabledRevokeUser={currentUser || organizationCreator || userRestrictions.usersDelete}
            onEdit={onEditUser}
            onRevokeUser={onRevokeUser} />
        );
      }
    }
  ], [
    uiTexts,
    activeOrganization,
    businessesData,
    userData,
    disabled,
    guestsOnly,
    userRestrictions,
    superAdminOrganizationAccess,
    onEditUser,
    onRevokeUser
  ]);

  return (
    <Table
      className={classNames(Css.usersTable, className)}
      columns={tableColumns}
      data={tableData}
      pageSize={data.length}
      getTrProps={getTrProps}
      getTdProps={getTdProps} />
  );
};

export default React.memo(UsersTable);
