import Css from "./style.module.scss";

import demoBackground from "assets/demoBackground.jpg";

import * as Icons from "@phosphor-icons/react";
import { Button } from "shards-react";
import { FiPlusCircle } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import PostSignUp from "nlib/common/PostSignUp";
import React, { useCallback, useMemo } from "react";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const NoBusinesses = ({ onAddBusiness }) => {
  const dispatch = useDispatch();

  const { errors, uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const userRestrictions = useSelector(getUserRestrictions);

  const userData = useSelector(getUserData);

  const demoBackgroundStyle = useMemo(() => {
    return Utils.checkIsTouchDevice()
      ? {} : { backgroundImage: `linear-gradient(rgba(0, 49, 102, 0.3), rgba(0, 49, 102, 0.3)), url(${demoBackground})` };
  }, []);

  const businessUser = UserRoles.checkIsBusiness(userData.role);

  const { currency, countryCode } = activeOrganization || {};

  const [businessCreationGreetingA, businessCreationGreetingB] = messages?.businessCreationGreeting || [];

  const handleAddNewBusinessButtonClick = useCallback(async() => {
    const result = await dispatch(UiActions.showAddBusinessWindow());

    if (result) onAddBusiness(result);
  }, [onAddBusiness, dispatch]);

  if (!businessUser && ((activeOrganization.billingId && !activeOrganization.subscriptionId) || userData.holdUser)) {
    return (
      <div className={Css.noBusiness} style={demoBackgroundStyle}>
        <PostSignUp />
      </div>
    );
  }

  if (businessUser || !currency || !countryCode || userRestrictions.businessesCreate) {
    const [accessDeniedErrorA, accessDeniedErrorB] = errors.accessDenied;

    return (
      <div className={Css.noBusiness} style={demoBackgroundStyle}>
        <div className={Css.container}>
          <Icons.Warning weight="thin" className={Css.icon} />
          <div className={Css.title}>{accessDeniedErrorA}</div>
          <div className={Css.description}>{accessDeniedErrorB}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={Css.noBusiness} style={demoBackgroundStyle}>
      <div className={classNames(Css.container, Css.addNewBusiness)}>
        <h3>{businessCreationGreetingA}</h3>
        <h5>{businessCreationGreetingB}</h5>
        <div>
          <Button
            size="sm"
            disabled={userRestrictions.businessesCreate}
            onClick={handleAddNewBusinessButtonClick}>
            <span><FiPlusCircle /><span>{uiTexts.addNewBusiness}</span></span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoBusinesses);
