
import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Container, Navbar } from "shards-react";
import { DropDownSelect } from "lib/common";
import { getActiveOrganization } from "selectors/organizations";
import { getLanguage, getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AuthZeroActions from "actions/AuthZeroActions";
import BusinessSelector from "./lib/BusinessSelector";
import CommentsNotificationPopup from "./lib/CommentsNotificationPopup";
import Constants from "const/Constants";
import EditOrganizationSettingsWindow from "./lib/EditOrganizationSettingsWindow";
import EditUserSettingsWindow from "./lib/EditUserSettingsWindow";
import Pages from "lib/pages/Pages";
import React, { useCallback, useEffect, useState } from "react";
import SettingsDropDown from "./lib/SettingsDropDown";
import StripeActions from "actions/StripeActions";
import TextsActions from "actions/TextsActions";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UserActions from "actions/UserActions";
import Utils from "utils/Utils";

const LANGUAGES_LIST = Object.values(Constants.LANGUAGES);

const Header = ({ onAddNewBusiness }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { businessId } = useParams();

  const businessesData = useSelector(getUserBusinessesData);

  const language = useSelector(getLanguage);

  const userData = useSelector(getUserData);

  const { messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const [editUserSettingsWindowOpened, setEditUserSettingsWindowOpened] = useState(false);

  const [editOrganizationSettingsWindowOpened, setEditOrganizationSettingsWindowOpened] = useState(false);

  const [editOrganizationSettingsWindowTab, setEditOrganizationSettingsWindowTab] = useState(null);

  const { fullName } = userData;

  const handleUserSettingsOpen = useCallback(() => {
    dispatch(UiActions.toggleModalContent(true));
    setEditUserSettingsWindowOpened(true);
  }, [dispatch]);

  const handleOrganizationSettingsOpen = useCallback(() => {
    dispatch(UiActions.toggleModalContent(true));
    setEditOrganizationSettingsWindowOpened(true);
  }, [dispatch]);

  const handleBillingOpen = useCallback(async() => {
    let sessionUrl = null;

    if (activeOrganization.billingId && activeOrganization.subscriptionId) {
      sessionUrl = await dispatch(StripeActions.createBillingPortalSession());
    } else {
      sessionUrl = await dispatch(StripeActions.createSetupCheckoutSession());
    }

    window.location.href = sessionUrl;
  }, [activeOrganization, dispatch]);

  const handleLogout = useCallback(async() => {
    const modalResult = await dispatch(UiActions.showModal(messages.logoutConfirm, null, true));

    if (modalResult) dispatch(AuthZeroActions.logoutUser());
  }, [messages, dispatch]);

  const handleLanguageSelectChange = useCallback(async(value) => {
    const routeInfo = Pages.getCurrentRouteInfo(pathname);

    dispatch(UiActions.togglePreloader(true));
    await dispatch(TextsActions.setAppLanguage(value));
    if (`/${routeInfo.businessId}` === UiRoutes.INTEGRATIONS) {
      window.location.reload();
    } else {
      dispatch(UiActions.togglePreloader(false));
    }
  }, [pathname, dispatch]);

  const handleOrganizationSettingsWidnowClose = useCallback(() => {
    setEditOrganizationSettingsWindowOpened(false);
    setEditOrganizationSettingsWindowTab(null);
  }, []);

  const handleUserSettingsWindowClose = useCallback((result) => {
    if (result) {
      const { firstName, lastName } = result;

      dispatch(UserActions.editUser({
        firstName: firstName.trim(),
        lastName: lastName.trim()
      }));
    }
    setEditUserSettingsWindowOpened(false);
  }, [dispatch]);

  const handleNavBarBusinessSelect = useCallback((value) => {
    const currentRoute = Pages.getCurrentRouteInfo(pathname).route;

    const found = businessesData.find(({ id }) => id === value);

    dispatch(UiActions.togglePreloader(true, found ? found.name : undefined));

    history.push(`/${value}${currentRoute || ""}`);
  }, [businessesData, dispatch, history, pathname]);

  useEffect(() => {
    if (!userData.firstName || !userData.lastName) {
      dispatch(UiActions.toggleModalContent(true));
      setEditUserSettingsWindowOpened(true);
    }
  }, [dispatch, userData.firstName, userData.lastName]);

  return (
    <Container
      fluid
      className={Css.header}
      data-superaccess={activeOrganization.superAdminAccess ? "" : undefined}>
      <Navbar>
        {businessId
          ? (
            <div className={Css.businessSelectorWrapper}>
              <BusinessSelector
                onBusinessSelect={handleNavBarBusinessSelect}
                onAddNewBusiness={onAddNewBusiness} />
            </div>
          )
          : (
            <div className={Css.organizationName} title={activeOrganization.name}>
              <img src={Utils.getFlagIconUrl(activeOrganization.countryCode)} />
              <span>{activeOrganization.name}</span>
            </div>
          )}
        {fullName && (
          <div className={Css.userProfile}>
            <SettingsDropDown
              showActiveOrganizationName={businessId}
              onUserSettingsOpen={handleUserSettingsOpen}
              onOrganizationSettingsOpen={handleOrganizationSettingsOpen}
              onBillingOpen={handleBillingOpen}
              onLogout={handleLogout} />
          </div>
        )}
        <CommentsNotificationPopup />
        <DropDownSelect
          right
          outline
          size="sm"
          theme="white"
          toggleClassName={Css.langSelector}
          menuClassName={CommonCss.zIndexB}
          initialValue={language}
          options={LANGUAGES_LIST.map(({ code, name: langName }) => {
            return {
              value: code,
              label: (<span>{`${langName} (${code.toUpperCase()})`}</span>)
            };
          })}
          onChange={handleLanguageSelectChange} />
        {editOrganizationSettingsWindowOpened && (
          <EditOrganizationSettingsWindow
            initialTab={editOrganizationSettingsWindowTab}
            onClose={handleOrganizationSettingsWidnowClose} />
        )}
        {editUserSettingsWindowOpened && (
          <EditUserSettingsWindow userData={userData} onClose={handleUserSettingsWindowClose} />
        )}
      </Navbar>
    </Container>
  );
};

export default React.memo(Header);
