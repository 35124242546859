import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import * as Yup from "yup";
import { Badge, ButtonRadioGroup, Form, ModalWindow } from "lib/common";
import {
  Button,
  Col,
  FormGroup,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { FaCircle } from "react-icons/fa";
import {
  FiBriefcase,
  FiRefreshCw,
  FiSettings,
  FiStar,
  FiTrash2 as FiTrash,
  FiUserPlus,
  FiUsers
} from "react-icons/fi";
import { bind } from "decko";
import {
  checkBusinessesFetching,
  getAllBusinessesData,
  getSelectedBusinessId,
  getUserBusinessesData
} from "selectors/businesses";
import {
  checkOrganizationsFetching,
  checkUsersFetching,
  getActiveOrganization,
  getAllUsersData,
  getOrganizationsData
} from "selectors/organizations";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import { getUserData, getUserRestrictions } from "selectors/user";
import BusinessesActions from "actions/BusinessesActions";
import BusinessesTable from "./lib/BusinessesTable";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { PureComponent } from "react";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import UserDetailsWindow from "./lib/UserDetailsWindow";
import UsersTable from "./lib/UsersTable";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const mapStateToProps = (state) => ({
  fetchingData: checkOrganizationsFetching(state) || checkBusinessesFetching(state) || checkUsersFetching(state),
  selectedBusinessId: getSelectedBusinessId(state),
  textsData: getTextsData(state),
  userData: getUserData(state),
  userRestrictions: getUserRestrictions(state),
  usersData: getAllUsersData(state),
  organizationsData: getOrganizationsData(state),
  businessesData: getAllBusinessesData(state),
  userBusinessesData: getUserBusinessesData(state),
  activeOrganization: getActiveOrganization(state)
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  showSetupBackupWindow: (...args) => dispatch(UiActions.showSetupBackupWindow(...args)),
  toggleModalContent: (...args) => dispatch(UiActions.toggleModalContent(...args)),
  fetchBusiness: (...args) => dispatch(BusinessesActions.fetchBusiness(...args)),
  fetchBusinessesList: (...args) => dispatch(BusinessesActions.fetchBusinessesList(...args)),
  fetchUsersList: (...args) => dispatch(OrganizationsActions.fetchUsersList(...args)),
  changeActiveOrganization: (...args) => dispatch(OrganizationsActions.changeActiveOrganization(...args)),
  selectBusiness: (...args) => dispatch(BusinessesActions.selectBusiness(...args)),
  editBusinessSettings: (...args) => dispatch(BusinessesActions.editBusinessSettings(...args)),
  deleteBusiness: (...args) => dispatch(BusinessesActions.deleteBusiness(...args)),
  editUser: (...args) => dispatch(OrganizationsActions.editUser(...args)),
  inviteUser: (...args) => dispatch(OrganizationsActions.inviteUser(...args)),
  revokeUser: (...args) => dispatch(OrganizationsActions.revokeUser(...args)),
  deleteOrganization: (...args) => dispatch(OrganizationsActions.deleteOrganization(...args)),
  deleteAllInactiveOrganizations: (...args) => dispatch(OrganizationsActions.deleteAllInactiveOrganizations(...args)),
  togglePreloader: (...args) => dispatch(UiActions.togglePreloader(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class EditOrganizationSettingsWindow extends PureComponent {
  static TABS = {
    USERS: "users",
    GUEST_USERS: "guestUsers",
    BUSINESSES: "businesses"
  };

  static INVITE_VALIDATION_SCHEMA = Yup.object().shape({
    invitationRole: Yup.string().trim().required(),
    invitationEmail: Yup.string().trim().email().required()
  });

  constructor(props) {
    super(props);

    const { TABS } = EditOrganizationSettingsWindow;

    const { activeOrganization, initialTab } = this.props;

    this.tableWrapperElementRef = React.createRef();

    this.state = {
      activeTab: initialTab || TABS.USERS,
      activeOrganizationId: activeOrganization.id,
      editUserDetailsWindowOpened: false,
      editableBusinessId: null,
      editableUserEmail: undefined
    };
  }

  reloadPage(redirectToRoot = false) {
    const { onClose, togglePreloader } = this.props;

    onClose(false);
    togglePreloader(true);
    if (redirectToRoot) window.location.href = UiRoutes.MAIN;
    else window.location.reload();
  }

  componentDidMount() {
    const { fetchUsersList } = this.props;

    fetchUsersList();
  }

  @bind
  handleClose(result) {
    this.props.onClose(result);
  }

  @bind
  handleActiveOrganizationSelectChange({ target: { value } }) {
    this.setState({ activeOrganizationId: value });
  }

  @bind
  handleActiveTableSelectChange(value) {
    this.setState({ activeTab: value });
  }

  @bind
  handleChangeOrganizationButtonClick() {
    this.props.changeActiveOrganization(this.state.activeOrganizationId)
      .then((result) => {
        if (result) this.reloadPage(true);
      });
  }

  @bind
  handleInviteUserButtonClick() {
    this.props.toggleModalContent(true);
    this.setState({ editUserDetailsWindowOpened: true });
  }

  @bind
  handleSetupBackupButtonClick() {
    this.props.showSetupBackupWindow();
  }

  @bind
  handleDeleteOrganizationButtonClick() {
    (async() => {
      const { textsData: { uiTexts }, activeOrganization, showModal, togglePreloader, deleteOrganization } = this.props;

      const modalResult = await showModal(
        `[ ${activeOrganization.name} ]\n\nAre you sure you want to perform this action?`,
        uiTexts.confirm,
        true,
        null,
        "YES, DELETE THIS ORGANIZATION"
      );

      if (modalResult) {
        togglePreloader(true);
        this.props.onClose(false);

        const successful = await deleteOrganization(activeOrganization.id);

        if (successful) this.reloadPage();
      }
    })();
  }

  @bind
  handleDeleteAllInactiveOrganizationsButtonClick() {
    (async() => {
      const { organizationsData, showModal, togglePreloader, deleteAllInactiveOrganizations } = this.props;

      const superAdminOrganizations = organizationsData.filter(({ superAdminAccess }) => superAdminAccess);

      const superAdminInactiveOrganizations = superAdminOrganizations.filter(({ inactive }) => inactive);

      // eslint-disable-next-line
      const promptResult = prompt(
        [
          `[ DELETE ${superAdminInactiveOrganizations.length} INACTIVE ORGANIZATIONS ]`,
          "To confirm, type exactly: DELETE ALL INACTIVE ORGANIZATIONS"
        ].join("\n\n")
      );

      if (promptResult === "DELETE ALL INACTIVE ORGANIZATIONS") {
        togglePreloader(true);
        this.props.onClose(false);

        const successful = await deleteAllInactiveOrganizations();

        if (successful) {
          await showModal(`${superAdminInactiveOrganizations.length} organizations have been scheduled for deletion`);
        }
        togglePreloader(false);
      }
    })();
  }

  @bind
  handleTableRevokeUser(email) {
    (async() => {
      const { textsData: { messages }, userData, usersData, revokeUser, fetchUsersList, showModal } = this.props;

      const { pendingInvite } = usersData.find((item) => item.email === email);

      const modalResult = await showModal(messages.actionConfirm, null, true);

      if (modalResult) {
        const result = await revokeUser(email, pendingInvite);

        await fetchUsersList();
        if (result && email === userData.email) this.reloadPage();
      }
    })();
  }

  @bind
  handleTableDeleteBusiness(businessId) {
    (async() => {
      const {
        textsData: { messages },
        selectedBusinessId,
        activeOrganization: { billingId, subscriptionId },
        fetchBusiness,
        fetchBusinessesList,
        deleteBusiness,
        showModal
      } = this.props;

      const modalResult = await showModal(
        billingId && subscriptionId ? messages.deleteBusinessConfirm : messages.actionConfirm,
        null,
        true
      );

      if (modalResult) {
        const result = await deleteBusiness(businessId);

        if (result) {
          if (businessId === selectedBusinessId) this.reloadPage(true);
          else {
            await fetchBusinessesList(false, false);
            if (selectedBusinessId) await fetchBusiness(selectedBusinessId, false);
          }
        }
      }
    })();
  }

  @bind
  handleTableEditUser(email) {
    this.props.toggleModalContent(true);
    this.setState({ editUserDetailsWindowOpened: true, editableUserEmail: email });
  }

  @bind
  handleEditUserDetailsWindowClose(result) {
    if (result) {
      (async() => {
        const {
          userEmail,
          userRole,
          userPhoneNumber,
          userBusinesses,
          emailNotifications,
          smsNotifications
        } = result;

        const {
          selectedBusinessId,
          userData,
          businessesData,
          inviteUser,
          editUser,
          fetchUsersList,
          selectBusiness
        } = this.props;

        const { editableUserEmail } = this.state;

        const businessIds = userBusinesses.length
          ? businessesData.filter(({ name }) => userBusinesses.includes(name)).map(({ id }) => id)
          : [];

        if (editableUserEmail) {
          await editUser({
            businessIds,
            emailNotifications,
            smsNotifications,
            email: userEmail,
            phone: userPhoneNumber,
            role: userRole
          });
          if (selectedBusinessId && userData.email === editableUserEmail
              && businessIds.length && !businessIds.includes(selectedBusinessId)) {
            await selectBusiness(businessIds[0]);
          }
        } else {
          await inviteUser({
            businessIds,
            emailNotifications,
            smsNotifications,
            email: userEmail,
            phone: userPhoneNumber,
            role: userRole
          });
        }
        await fetchUsersList();
        if (!editableUserEmail) {
          this.tableWrapperElementRef.current.scrollTop = this.tableWrapperElementRef.current.scrollHeight;
        }
      })();
    }

    this.setState({ editUserDetailsWindowOpened: false, editableUserEmail: undefined });
  }

  render() {
    const { TABS: { USERS, GUEST_USERS, BUSINESSES } } = EditOrganizationSettingsWindow;

    const {
      textsData: { uiTexts },
      userData,
      userRestrictions,
      usersData,
      userBusinessesData,
      organizationsData,
      activeOrganization,
      selectedBusinessId,
      fetchingData
    } = this.props;

    const {
      activeTab,
      activeOrganizationId,
      editUserDetailsWindowOpened,
      editableUserEmail
    } = this.state;

    const superAdminOrganizationAccess = activeOrganization.superAdminAccess;

    const userHasSuperAdminRights = !!userData.superAdmin;

    const regularUsersData = usersData.filter((user) => !user.guestUser);

    const guestUsersData = usersData.filter((user) => user.guestUser);

    const userOrganizations = organizationsData.filter(({ superAdminAccess }) => !superAdminAccess);

    const superAdminOrganizations = organizationsData.filter(({ superAdminAccess }) => superAdminAccess);

    const superAdminActiveOrganizations = superAdminOrganizations.filter(({ inactive }) => !inactive);

    const superAdminInactiveOrganizations = superAdminOrganizations.filter(({ inactive }) => inactive);

    const superAdminOrganizationActive = activeOrganization.id === activeOrganizationId && superAdminOrganizationAccess;

    const { backupInfo: { accountConnected = false, accountEmail = "" } = {} } = activeOrganization;

    return (
      <ModalWindow
        className={Css.editOrganizationSettingsWindow}
        config={{ headerText: uiTexts.organizationSettings }}
        disabledButtons={fetchingData}
        iconComponent={FiSettings}
        onClose={this.handleClose}>
        <Form>
          <FormGroup row>
            <div>{uiTexts.activeOrganization}</div>
          </FormGroup>
          <FormGroup row className={Css.activeOrganizationGroup}>
            <Row form>
              <Col>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText data-superaccess={superAdminOrganizationActive ? "" : undefined}>
                      <span>
                        {superAdminOrganizationActive && <FiStar />}
                        <span>{uiTexts.organization}</span>
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    value={activeOrganizationId}
                    disabled={fetchingData}
                    onChange={this.handleActiveOrganizationSelectChange}>
                    {!!userOrganizations.length && (
                      <optgroup label={uiTexts.organizations}>
                        {userOrganizations.map(({ id, name }) => (
                          <option key={id} value={id}>{name}</option>
                        ))}
                      </optgroup>
                    )}
                    {!!superAdminOrganizations.length && (
                      [
                        superAdminActiveOrganizations.length && { value: superAdminActiveOrganizations, active: true },
                        superAdminInactiveOrganizations.length && { value: superAdminInactiveOrganizations, active: false }
                      ].filter(Boolean).map(({ value: organizations, active }) => {
                        const label = active ? "Active" : "Inactive";

                        return (
                          <optgroup key={active} label={`God mode: ON :) -> ${label} organizations [${organizations.length}]`}>
                            {organizations.map(({
                              id,
                              name,
                              countryCode,
                              lastActiveAt,
                              businessesCount = 0,
                              businessOrganization,
                              billingId,
                              subscriptionId,
                              customBilling,
                              appsumoValue
                            }) => {
                              const lastActiveDaysAgo = moment().diff(lastActiveAt, "days").toString();

                              const lastActiveDaysAgoTemplate = "000".slice(lastActiveDaysAgo.length) + lastActiveDaysAgo;

                              const businessCountTemplate = "000".slice(businessesCount.toString().length) + businessesCount;

                              const [subscriptionMode] = [
                                billingId && subscriptionId && "+",
                                billingId && !subscriptionId && "-",
                                appsumoValue && "a",
                                customBilling && "c",
                                "x"
                              ].filter(Boolean);

                              const prefixParts = [
                                `${countryCode.toUpperCase()}`,
                                `$${subscriptionMode}`,
                                `#${businessCountTemplate}`,
                                `^${lastActiveDaysAgoTemplate}`
                              ];

                              const prefix = `[${prefixParts.join(" ")}]${businessOrganization ? "*" : ""}`;

                              return (
                                <option key={id} value={id}>{`${prefix} ${name}`}</option>
                              );
                            })}
                          </optgroup>
                        );
                      }))}
                  </FormSelect>
                  <InputGroupAddon type="append">
                    <Button
                      size="sm"
                      disabled={fetchingData || activeOrganizationId === activeOrganization.id}
                      onClick={this.handleChangeOrganizationButtonClick}>
                      <FiRefreshCw />
                      <span>{uiTexts.change}</span>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className={CommonCss.flexCenter}>
            <Row>
              <Col>
                <ButtonRadioGroup
                  outline
                  size="sm"
                  theme="light"
                  className={Css.tabSelector}
                  initialValue={activeTab}
                  disabled={fetchingData || !userBusinessesData.length}
                  options={[
                    {
                      value: USERS,
                      label: <>
                        <FiUsers />
                        <span>{uiTexts.users}</span>
                        {userRestrictions.usersRead || <Badge counter value={regularUsersData.length} />}
                      </>
                    },
                    {
                      value: BUSINESSES,
                      label: <>
                        <FiBriefcase />
                        <span>{uiTexts.businesses}</span>
                        <Badge counter value={userBusinessesData.length} />
                      </>
                    }
                  ]}
                  onChange={this.handleActiveTableSelectChange} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className={Css.dataTableGroup}>
            <Row form>
              <Col className={Css.tableColumn}>
                <div ref={this.tableWrapperElementRef} className={Css.tableWrapper}>
                  {{
                    [USERS]: regularUsersData.length
                      ? <UsersTable
                        className={Css.dataTable}
                        data={regularUsersData}
                        userData={userData}
                        userRestrictions={userRestrictions}
                        superAdminOrganizationAccess={superAdminOrganizationAccess}
                        disabled={fetchingData}
                        onEditUser={this.handleTableEditUser}
                        onRevokeUser={this.handleTableRevokeUser} />
                      : <div className={CommonCss.textCenter}>{uiTexts.noData}</div>,
                    [GUEST_USERS]: guestUsersData.length
                      ? <UsersTable
                        guestsOnly
                        className={Css.dataTable}
                        data={guestUsersData}
                        userData={userData}
                        userRestrictions={userRestrictions}
                        superAdminOrganizationAccess={superAdminOrganizationAccess}
                        disabled={fetchingData}
                        onEditUser={this.handleTableEditUser}
                        onRevokeUser={this.handleTableRevokeUser} />
                      : <div className={CommonCss.textCenter}>{uiTexts.noData}</div>,
                    [BUSINESSES]:
                      userBusinessesData.length
                        ? <BusinessesTable
                          className={Css.dataTable}
                          data={userBusinessesData}
                          selectedBusinessId={selectedBusinessId}
                          userRestrictions={userRestrictions}
                          disabled={fetchingData}
                          onDeleteBusiness={this.handleTableDeleteBusiness} />
                        : <div className={CommonCss.textCenter}>{uiTexts.noData}</div>
                  }[activeTab]}
                </div>
              </Col>
            </Row>
          </FormGroup>
          {(userHasSuperAdminRights
            || (activeTab === USERS && !userRestrictions.usersCreate)
            || (activeTab === BUSINESSES && !Utils.checkIsTouchDevice())) && <>
            <FormGroup
              row
              className={classNames(Css.otherGroup, CommonCss.flexCenter)}>
              {activeTab === USERS && <Button
                size="sm"
                disabled={fetchingData}
                onClick={this.handleInviteUserButtonClick}>
                <FiUserPlus />
                <span>{uiTexts.inviteUser}</span>
              </Button>}
              {activeTab === BUSINESSES && !Utils.checkIsTouchDevice() && <Button
                size="sm"
                theme="light"
                disabled={fetchingData}
                className={Css.setupBackupButton}
                onClick={this.handleSetupBackupButtonClick}>
                <span>
                  <img src={googleDrive} />
                  <span>
                    {accountEmail
                      ? (accountConnected ? uiTexts.backupEnabled : uiTexts.backupDisabled)
                      : uiTexts.setupGoogleDriveBackup}
                  </span>
                  {accountEmail && <FaCircle className={accountConnected ? CommonCss.positiveText : CommonCss.negativeText} />}
                </span>
              </Button>}
              {userHasSuperAdminRights && <Button
                outline
                theme="danger"
                size="sm"
                className={Css.deleteOrganizationButton}
                disabled={fetchingData}
                onClick={this.handleDeleteOrganizationButtonClick}>
                <FiTrash />
                <span>Delete organization</span>
              </Button>}
              {userHasSuperAdminRights && !!superAdminInactiveOrganizations.length && <Button
                theme="danger"
                size="sm"
                className={Css.deleteOrganizationButton}
                disabled={fetchingData}
                onClick={this.handleDeleteAllInactiveOrganizationsButtonClick}>
                <FiTrash />
                <span><b>{`Delete ${superAdminInactiveOrganizations.length} inactive organizations`}</b></span>
              </Button>}
            </FormGroup>
          </>}
        </Form>
        {editUserDetailsWindowOpened && <UserDetailsWindow
          initialEmail={editableUserEmail}
          disabled={!!(editableUserEmail && (userRestrictions.usersUpdate || superAdminOrganizationAccess))}
          onClose={this.handleEditUserDetailsWindowClose} />}
      </ModalWindow>
    );
  }
}

export default EditOrganizationSettingsWindow;
