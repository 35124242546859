import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import {
  Col,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip
} from "shards-react";
import { FiAlertTriangle, FiUser, FiUserPlus } from "react-icons/fi";
import { Form, ModalWindow, TagsInput } from "lib/common";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getAllBusinessesData, getBusinessesNames } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const INVITE_VALIDATION_SCHEMA = Yup.object().shape({
  userEmail: Yup.string().trim().email().required(),
  userRole: Yup.string().trim().required(),
  userPhone: Yup.string().trim().matches(Constants.PHONE_INPUT_PATTERN),
  emailNotifications: Yup.bool().required()
});

const USER_EMAIL_VALIDATION_SCHEMA = Yup.reach(INVITE_VALIDATION_SCHEMA, "userEmail");

const USER_PHONE_NUMBER_VALIDATION_SCHEMA = Yup.reach(INVITE_VALIDATION_SCHEMA, "userPhone");

const UserDetailsWindow = ({ initialEmail = "", disabled, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessesNames = useSelector(getBusinessesNames);

  const businessesData = useSelector(getAllBusinessesData);

  const userData = useSelector(getUserData);

  const usersData = useSelector(getAllUsersData);

  const smsNotificationsAllowed = Constants.SMS_NOTIFICATIONS_ALLOWED_COUNTRIES
    .includes(activeOrganization.countryCode);

  const regularUsersData = useMemo(() => {
    return usersData.filter(({ guestUser }) => !guestUser);
  }, [usersData]);

  const editableUser = useMemo(() => {
    return initialEmail && usersData.find((user) => user.email === initialEmail);
  }, [initialEmail, usersData]);

  const initialRole = useMemo(() => {
    return (editableUser && editableUser.role) || "";
  }, [editableUser]);

  const initialBusinesses = useMemo(() => {
    if (!editableUser) {
      return {
        toggleRedraw: false,
        allAllowed: false,
        list: []
      };
    }

    const userBusinessNames = editableUser
      ? editableUser.businessIds
        .map((businessId) => {
          const businessData = businessesData.find((business) => business.id === businessId);

          return businessData ? businessData.name : "";
        })
        .filter(Boolean)
        .sort()
      : [];

    return {
      toggleRedraw: false,
      allAllowed: !UserRoles.checkIsBusiness(initialRole) && editableUser && editableUser.businessIds.length === 0,
      list: userBusinessNames
    };
  }, [businessesData, editableUser, initialRole]);

  const initialEmailNotifications = useMemo(() => {
    return editableUser ? editableUser.emailNotifications : true;
  }, [editableUser]);

  const initialSmsNotifications = useMemo(() => {
    return editableUser ? !!editableUser.smsNotifications : false;
  }, [editableUser]);

  const initialPhoneNumber = useMemo(() => {
    return (editableUser && editableUser.phone) || "";
  }, [editableUser]);

  const [userEmail, setUserEmail] = useState(initialEmail);

  const [userPhoneNumber, setUserPhoneNumber] = useState(initialPhoneNumber);

  const [userRole, setUserRole] = useState(initialRole);

  const [userBusinesses, setUserBusinesses] = useState(initialBusinesses);

  const [emailNotifications, setEmailNotifications] = useState(initialEmailNotifications);

  const [smsNotifications, setSmsNotifications] = useState(initialSmsNotifications);

  const businessUserRoleSelected = useMemo(() => {
    return UserRoles.checkIsBusiness(userRole);
  }, [userRole]);

  const formDataValid = useMemo(() => {
    return INVITE_VALIDATION_SCHEMA.isValidSync({ userRole, userEmail, emailNotifications });
  }, [userEmail, userRole, emailNotifications]);

  const uniqueUserEmail = useMemo(() => {
    return !regularUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, regularUsersData]);

  const validEmail = useMemo(() => {
    return USER_EMAIL_VALIDATION_SCHEMA.isValidSync(userEmail) && uniqueUserEmail;
  }, [userEmail, uniqueUserEmail]);

  const validPhoneNumber = useMemo(() => {
    return USER_PHONE_NUMBER_VALIDATION_SCHEMA.isValidSync(userPhoneNumber) && !!Utils.normalizePhoneNumber(userPhoneNumber);
  }, [userPhoneNumber]);

  const formHasChanges = useMemo(() => {
    return !Utils.checkDeepEquality(
      {
        userRole,
        emailNotifications,
        smsNotifications,
        userPhoneNumber,
        allowAllBusinessesAccess: userBusinesses.allAllowed,
        userBusinesses: userBusinesses.list
      },
      {
        userRole: initialRole,
        emailNotifications: initialEmailNotifications,
        smsNotifications: initialSmsNotifications,
        userPhoneNumber: initialPhoneNumber,
        allowAllBusinessesAccess: initialBusinesses.allAllowed,
        userBusinesses: initialBusinesses.list
      }
    );
  }, [
    userRole,
    emailNotifications,
    smsNotifications,
    userBusinesses,
    userPhoneNumber,
    initialPhoneNumber,
    initialRole,
    initialEmailNotifications,
    initialSmsNotifications,
    initialBusinesses
  ]);

  const userRolesOptions = useMemo(() => {
    return UserRoles.getRoles().map(({ roleId, langId }) => {
      return (
        <option key={roleId} value={roleId}>
          {editableUser && (editableUser.guestUser || editableUser.sub === activeOrganization.creatorId)
            ? (editableUser.guestUser ? uiTexts.guestAccess : uiTexts.organizationOwner)
            : uiTexts[langId]}
        </option>
      );
    });
  }, [uiTexts, activeOrganization, editableUser]);

  const handleClose = useCallback((result) => {
    if (result) onClose({
      userEmail,
      userRole,
      userPhoneNumber,
      userBusinesses: userBusinesses.list,
      emailNotifications,
      smsNotifications
    });
    else onClose();
  }, [
    userEmail,
    userRole,
    userPhoneNumber,
    userBusinesses,
    emailNotifications,
    smsNotifications,
    onClose
  ]);

  const handleUserEmailInputChange = useCallback(({ target: { value } }) => {
    setUserEmail(value);
  }, []);

  const handleUserPhoneInputChange = useCallback(({ target: { value } }) => {
    setUserPhoneNumber(value);
  }, []);

  const handleUserRoleSelectChange = useCallback(({ target: { value } }) => {
    const accountantAdmin = value === UserRoles.ACCOUNTANT_ADMIN.roleId;

    setUserRole(value);
    setUserBusinesses((previousState) => ({
      toggleRedraw: !previousState.toggleRedraw,
      allAllowed: accountantAdmin,
      list: []
    }));
  }, []);

  const handleUserBusinessesInputChange = useCallback(({ target: { value } }) => {
    setUserBusinesses((previousState) => ({
      ...previousState,
      list: (Array.isArray(value) ? value : [])
        .filter((businessName) => businessesData.some(({ name }) => name === businessName))
        .sort()
    }));
  }, [businessesData]);

  const handleUserBusinessesInputBlur = useCallback(() => {
    setUserBusinesses((previousState) => ({
      ...previousState,
      toggleRedraw: !previousState.toggleRedraw
    }));
  }, []);

  const handleAllBusinessAllowedToggleChange = useCallback(() => {
    setUserBusinesses((previousState) => ({
      toggleRedraw: !previousState.toggleRedraw,
      allAllowed: !previousState.allAllowed,
      list: []
    }));
  }, []);

  const handleEmailNotificationsToggleChange = useCallback(() => {
    setEmailNotifications((previousState) => !previousState);
  }, []);

  const handleSmsNotificationsToggleChange = useCallback(() => {
    setSmsNotifications((previousState) => !previousState);
  }, []);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.userDetailsWindow}
      config={{
        headerText: initialEmail ? uiTexts.editUser : uiTexts.inviteUser,
        okButtonText: initialEmail ? uiTexts.save : uiTexts.invite,
        confirm: true
      }}
      disabledOkButton={!formDataValid
        || (!userBusinesses.list.length && !userBusinesses.allAllowed)
        || (initialEmail ? !formHasChanges : !uniqueUserEmail)
        || (smsNotificationsAllowed && smsNotifications && !validPhoneNumber)}
      iconComponent={initialEmail ? FiUser : FiUserPlus}
      onClose={handleClose}>
      <Form>
        <FormGroup row>
          <div>{uiTexts.userInfo}</div>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.email}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  id="editUserEmail"
                  type="email"
                  disabled={disabled || initialEmail}
                  placeholder={uiTexts.enterEmail}
                  value={userEmail}
                  invalid={!validEmail}
                  onChange={handleUserEmailInputChange} />
                <Tooltip open={!initialEmail && !uniqueUserEmail} target="#editUserEmail">
                  <b className={CommonCss.negativeText}>
                    <FiAlertTriangle />
                    <span>{uiTexts.userAlreadyAdded}</span>
                  </b>
                </Tooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.role}</span></InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  placeholder={userRole ? undefined : ""}
                  disabled={disabled
                    || userData.email === initialEmail
                    || (!initialEmail && !validEmail)
                    || (editableUser && editableUser.sub === activeOrganization.creatorId)
                    || (editableUser && editableUser.guestUser)}
                  value={userRole}
                  invalid={!userRole}
                  onChange={handleUserRoleSelectChange}>
                  {!userRole && <option value="">{uiTexts.selectRole}</option>}
                  {userRolesOptions}
                </FormSelect>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
        {(initialEmail || (validEmail && userRole)) && <>
          <FormGroup row>
            <div>{uiTexts.allowAccessToBusinesses}</div>
          </FormGroup>
          <FormGroup row className={CommonCss.flexCenter}>
            {!businessUserRoleSelected && <Row form>
              <Col>
                <FormCheckbox
                  toggle
                  checked={userBusinesses.allAllowed}
                  disabled={disabled || (!initialEmail && (!validEmail || !userRole))}
                  className={Css.allBusinessAllowedToggle}
                  onChange={handleAllBusinessAllowedToggleChange}>
                  <span>{messages.allowAllBusinessesAccess}</span>
                </FormCheckbox>
              </Col>
            </Row>}
            {(!userBusinesses.allAllowed || businessUserRoleSelected) && <Row form>
              <Col>
                <InputGroup disabled={!businessesNames.length}>
                  <InputGroupAddon type="prepend">
                    <InputGroupText><span>{uiTexts.businesses}</span></InputGroupText>
                  </InputGroupAddon>
                  <TagsInput
                    key={userBusinesses.toggleRedraw}
                    disabled={disabled || userBusinesses.allAllowed || (editableUser && editableUser.guestUser)}
                    placeholder={`${uiTexts.allowAccessTo}...`}
                    autoCompleteData={businessesNames}
                    value={userBusinesses.list}
                    invalid={!userBusinesses.list.length}
                    onChange={handleUserBusinessesInputChange}
                    onBlur={handleUserBusinessesInputBlur} />
                </InputGroup>
              </Col>
            </Row>}
          </FormGroup>
          <FormGroup row>
            <div>{uiTexts.notifications}</div>
          </FormGroup>
          <FormGroup row>
            <Row>
              <Col>
                <FormCheckbox
                  toggle
                  checked={emailNotifications}
                  disabled={disabled || (!initialEmail && (!validEmail || !userRole))}
                  className={Css.allBusinessAllowedToggle}
                  onChange={handleEmailNotificationsToggleChange}>
                  <span>{messages.allowEmailNotifications}</span>
                </FormCheckbox>
              </Col>
            </Row>
          </FormGroup>
          {smsNotificationsAllowed && (
            <>
              <FormGroup row>
                <Row>
                  <Col>
                    <FormCheckbox
                      toggle
                      checked={smsNotifications}
                      disabled={disabled}
                      className={Css.allBusinessAllowedToggle}
                      onChange={handleSmsNotificationsToggleChange}>
                      <span>{messages.allowSmsNotifications}</span>
                    </FormCheckbox>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup row className={Css.phoneNumberRow}>
                {smsNotifications && (
                  <Row form>
                    <Col>
                      <InputGroup>
                        <InputGroupAddon type="prepend">
                          <InputGroupText><span>{uiTexts.phone}</span></InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          id="editUserPhone"
                          type="tel"
                          disabled={disabled}
                          placeholder={uiTexts.enterPhoneNumber}
                          value={userPhoneNumber}
                          invalid={!validPhoneNumber}
                          onChange={handleUserPhoneInputChange} />
                      </InputGroup>
                    </Col>
                  </Row>
                )}
              </FormGroup>
            </>
          )}
        </>}
      </Form>
    </ModalWindow>
  );
};

export default React.memo(UserDetailsWindow);
