
import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Badge, DropDownSelect } from "lib/common";
import { Button } from "shards-react";
import { FaComment, FaCommentDots } from "react-icons/fa";
import { FiBookOpen, FiCheckSquare, FiFile, FiFileText, FiMessageCircle, FiRepeat } from "react-icons/fi";
import { checkCommentsFetching, getUnreadCommentsData } from "selectors/comments";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { COMMENTS } = Constants;

const MAX_TARGET_INFO_LENGTH = 20;

const COMMENT_TARGET_TYPES = {
  documents: { icon: FiFileText, path: "originalDocumentId" },
  transactions: {
    icon: FiRepeat,
    path: "timestamp",
    convertor: (timestamp) => moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
  },
  archive: { icon: FiFile, path: "attachment.originalName" },
  tasks: { icon: FiCheckSquare, path: "title" },
  audit: {
    icon: FiBookOpen,
    path: "timestamp",
    convertor: (timestamp) => moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
  }
};

const DropDownLabel = React.memo(({ comment, commentsCount }) => {
  const { targetType, targetData, text, createdAt, createdBy, businessName } = comment;

  const { icon: Icon, path, convertor = (value) => value } = COMMENT_TARGET_TYPES[targetType];

  const { uiTexts } = useSelector(getTextsData);

  const commentDate = useMemo(() => {
    return moment.duration(moment.utc(createdAt).diff(moment.utc())).humanize(true);
  }, [createdAt]);

  let targetInfo = convertor(Utils.getPropertyByPath(targetData || {}, path) || "");

  targetInfo = targetInfo.length > MAX_TARGET_INFO_LENGTH ? `${targetInfo.substr(0, MAX_TARGET_INFO_LENGTH)}...` : targetInfo;

  const { email } = createdBy || {};

  return (
    <>
      <div className={Css.content} disabled={!businessName}>
        <div className={Css.subTitle}>
          <Icon />
          <span className={Css.info}>
            {targetInfo && <>
              <span>{targetInfo}</span>
              <span> | </span>
            </>}
            <span>{businessName || `(${uiTexts.deletedBusiness.toUpperCase()})`}</span>
          </span>
          <Badge className={Css.notification} value={<><span>{commentsCount}</span><FaCommentDots /></>} />
        </div>
        <div>
          <span>{text}</span>
        </div>
        <div className={Css.userInfo}>
          <span>
            <span>{email || uiTexts.unknown}</span>
            <span>, </span>
            <span>{commentDate}</span>
          </span>
        </div>
      </div>
    </>
  );
});

const CommentsNotificationPopup = ({ disabled }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const unreadCommentsData = useSelector(getUnreadCommentsData);

  const commentsFetching = useSelector(checkCommentsFetching);

  const [dropdownExpanded, setDropdownExpanded] = useState(false);

  const commentsGroupedByTarget = useMemo(() => {
    const resultObject = unreadCommentsData.reduce((aggregator, comment) => {
      const { targetId } = comment;

      if (!aggregator[targetId]) aggregator[targetId] = [];
      aggregator[targetId].push(comment);

      return aggregator;
    }, {});

    Object.values(resultObject).forEach((group) => {
      group.sort(({ createdAt: createdAtA }, { createdAt: createdAtB }) => moment.utc(createdAtB).diff(moment.utc(createdAtA)));
    });

    const result = Object.values(resultObject).sort(([{ createdAt: createdAtA }], [{ createdAt: createdAtB }]) => {
      return moment.utc(createdAtB).diff(moment.utc(createdAtA));
    });

    return result;
  }, [unreadCommentsData]);

  const dropDownToggle = useMemo(() => {
    return (
      <span>
        {unreadCommentsData.length ? <FaComment /> : <FaCommentDots />}
        {!!unreadCommentsData.length && <Badge className={Css.notification} value={unreadCommentsData.length} />}
      </span>
    );
  }, [unreadCommentsData]);

  const dropDownItems = useMemo(
    () => {
      return commentsGroupedByTarget.map((commentsGroup) => {
        const [comment] = commentsGroup;

        return {
          value: comment.id,
          disabled: !comment.businessName,
          label: <DropDownLabel comment={comment} commentsCount={commentsGroup.length} />
        };
      });
    },
    [commentsGroupedByTarget]
  );

  const handleMarkAllReadClick = useCallback(async() => {
    setDropdownExpanded(false);

    const modalResult = await dispatch(UiActions.showModal(
      messages.actionConfirm,
      uiTexts.confirm,
      true
    ));

    if (!modalResult) return;

    dispatch(CommentsActions.markAllAsRead());
  }, [dispatch, messages.actionConfirm, uiTexts.confirm]);

  const prepend = useMemo(() => {
    return (
      <div className={Css.dropdownPrepend}>
        <div className={Css.title}>
          <FiMessageCircle />
          <span>{uiTexts.unreadComments}</span>
        </div>
        <Button size="sm" theme="secondary" disabled={commentsFetching} onClick={handleMarkAllReadClick}>
          {uiTexts.markAllAsRead}
        </Button>
      </div>
    );
  }, [commentsFetching, uiTexts, handleMarkAllReadClick]);

  const [dataLoaded, setDataLoaded] = useState(false);

  const handleCommentSelectChange = useCallback((commentId) => {
    const { businessId, targetId, targetType } = unreadCommentsData.find(({ id }) => id === commentId);

    const queryString = Utils.objectToQueryString(
      targetType === DataConstants.COMMENT_TARGET_TYPES.TASKS
        ? { editTask: targetId }
        : { editItem: targetId }
    );

    window.location.href = `/${businessId}${UiRoutes[targetType.toUpperCase()] || ""}/?${queryString}.${COMMENTS}`;
  }, [unreadCommentsData]);

  const handleToggleDropdownState = useCallback((state) => {
    setDropdownExpanded(state);
  }, []);

  useEffect(() => {
    dispatch(CommentsActions.fetchUnreadComments()).then((result) => {
      if (result) setDataLoaded(true);
    });
    setInterval(
      () => dispatch(CommentsActions.fetchUnreadComments()),
      Constants.DATA_LIST_UPDATE_INTERVAL
    );
  }, [dispatch]);

  return (
    <DropDownSelect
      right
      theme="black"
      expanded={dropdownExpanded}
      disabled={disabled || !dataLoaded || !unreadCommentsData.length ? true : undefined}
      className={Css.commentsNotificationPopup}
      menuClassName={classNames(Css.dropDownMenu, CommonCss.zIndexB)}
      toggleClassName={classNames(Css.dropDownToggle, !unreadCommentsData.length && "zero")}
      itemClassName={Css.dropDownItem}
      toggleContent={dropDownToggle}
      options={dropDownItems}
      prepend={!!dropDownItems.length && prepend}
      onChange={handleCommentSelectChange}
      onToggleState={handleToggleDropdownState} />
  );
};

export default React.memo(CommentsNotificationPopup);
