import { Redirect, useLocation } from "react-router-dom";
import { getSelectedBusinessId } from "selectors/businesses";
import { getUserRole } from "selectors/user";
import { useSelector } from "react-redux";
import Pages from "lib/pages/Pages";
import React from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const getRoute = ({ envVars: { editTask }, userRole, currentRoute }) => {
  if (editTask) return UiRoutes.TASKS;

  if (currentRoute && Pages.getPageData([userRole, currentRoute])) return currentRoute;

  return UiRoutes.DOCUMENTS;
};

const RedirectToDefaultRoute = () => {
  const { pathname, search } = useLocation();

  const envVars = Utils.parseQueryString(search);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const userRole = useSelector(getUserRole);

  const currentRoute = Pages.getCurrentRouteInfo(pathname).route;

  const route = getRoute({ envVars, userRole, currentRoute });

  if (!selectedBusinessId) return <Redirect to="/" />;

  return <Redirect to={`/${selectedBusinessId}${route}${search || ""}`} />;
};

export default RedirectToDefaultRoute;
