import { createSelector } from "reselect";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization, getAllUsersData } from "./organizations";
import { getInstitutionAccountsData } from "./institutions";
import Countries from "const/Countries";

export const checkTransactionsFetching = ({ transactions: { fetchingData } }) => fetchingData;

export const getLockedTransactions = ({ transactions: { locked } }) => locked;

export const getTransactionsTags = ({ transactions: { tags } }) => tags;

export const getTransactionsReasons = ({ transactions: { reasons } }) => reasons;

export const getTransactionsStats = ({ transactions: { stats } }) => stats;

export const getTransactionsData = createSelector(
  [
    ({ transactions: { data } }) => data,
    getAccountsData,
    getInstitutionAccountsData,
    getAllUsersData,
    getActiveOrganization
  ],
  (data, accounts, institutionAccounts, users, organization) => {
    const { countryCode } = organization || {};

    const accountsData = countryCode === Countries.CZ ? institutionAccounts : accounts;

    return data.map(({ accountId, askClientInfo, ...restData }) => ({
      ...restData,
      account: accountsData.find(({ id }) => id === accountId) || {},
      askClientInfo: askClientInfo && askClientInfo.askedEmail
        ? {
          ...askClientInfo,
          askedBy: users.find(({ id }) => id === askClientInfo.askedBy) || undefined,
          askedUser: users.find(({ email }) => email.toLowerCase() === askClientInfo.askedEmail.toLowerCase())
            || { email: askClientInfo.askedEmail }
            || null
        }
        : null
    }));
  }
);
