import { ButtonRadioGroup } from "lib/common";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  activeOrganization: getActiveOrganization(state),
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class AddBusinessWindowModeSelector extends PureComponent {
  static MODES = {
    BY_ID: "byId",
    MANUAL: "manual",
    INTEGRATION: "integration"
  };

  render() {
    const { MODES } = AddBusinessWindowModeSelector;

    const { textsData: { uiTexts }, selectedTab, onChange } = this.props;

    return (
      <ButtonRadioGroup
        outline
        size="sm"
        theme="light"
        key={selectedTab}
        initialValue={selectedTab}
        options={[
          { value: MODES.BY_ID, label: uiTexts.byId },
          { value: MODES.MANUAL, label: uiTexts.manually }
        ]}
        onChange={onChange} />
    );
  }
}

export const { MODES: ADD_BUSINESS_WINDOW_MODES } = AddBusinessWindowModeSelector;

export default AddBusinessWindowModeSelector;
