import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import {
  Col,
  FormGroup,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { FiAlertTriangle, FiUser } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const INVITE_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required()
});

const EditUserSettingsWindow = ({ userData, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const [firstName, setFirstName] = useState(userData.firstName || "");

  const [lastName, setLastName] = useState(userData.lastName || "");

  const formDataValid = useMemo(() => {
    return INVITE_VALIDATION_SCHEMA.isValidSync({ firstName, lastName });
  }, [firstName, lastName]);

  const formHasChanges = useMemo(() => {
    const { firstName: initialFirstName, lastName: initialLastName } = userData;

    return !Utils.checkDeepEquality(
      {
        firstName: initialFirstName,
        lastName: initialLastName
      },
      {
        firstName,
        lastName
      }
    );
  }, [userData, firstName, lastName]);

  const handleClose = useCallback((result) => {
    if (result) onClose({ firstName, lastName });
    else onClose();
  }, [onClose, firstName, lastName]);

  const handleFirstNameInputChange = useCallback(({ target: { value } }) => {
    setFirstName(value);
  }, []);

  const handleLastNameInputChange = useCallback(({ target: { value } }) => {
    setLastName(value);
  }, []);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.editUserSettingsWindow}
      config={{
        headerText: uiTexts.editUser,
        okButtonText: uiTexts.save,
        confirm: true
      }}
      disabledOkButton={!formHasChanges || !formDataValid}
      iconComponent={FiUser}
      onClose={handleClose}>
      <Form>
        {(!userData.firstName || !userData.lastName) && (
          <FormGroup row className={Css.messageGroup}>
            <div><FiAlertTriangle className={CommonCss.warningText} /><span>{messages.fillOutFullName}</span></div>
          </FormGroup>
        )}
        <FormGroup row>
          <div>{uiTexts.userInfo}</div>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.email}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  disabled
                  type="email"
                  placeholder={uiTexts.enterEmail}
                  value={userData.email} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.firstName}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  type="text"
                  placeholder={uiTexts.enterFirstName}
                  value={firstName}
                  invalid={!firstName.trim()}
                  onChange={handleFirstNameInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.lastName}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  type="text"
                  placeholder={uiTexts.enterLastName}
                  value={lastName}
                  invalid={!lastName.trim()}
                  onChange={handleLastNameInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(EditUserSettingsWindow);
