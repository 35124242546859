import CommonCss from "lib/common/style.module.scss";

import { ActionsButtons, Table } from "lib/common";
import { FiBriefcase, FiHome } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";

const REG_ID_CELL_WIDTH = 134;

const VAT_PAYER_CELL_WIDTH = 100;

const ACTIONS_CELL_WIDTH = 80;

const BusinessesTable = ({
  data,
  className,
  disabled,
  selectedBusinessId,
  userRestrictions,
  onDeleteBusiness
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const getTrProps = useCallback((state, { original: { id } }) => {
    if (selectedBusinessId === id) return { "data-highlight": "" };

    return {};
  }, [selectedBusinessId]);

  const tableColumns = useMemo(() => [
    {
      accessor: "regId",
      width: REG_ID_CELL_WIDTH,
      Header: <span>{uiTexts.businessId}</span>
    },
    {
      accessor: "name",
      Header: uiTexts.businessName,
      Cell: ({ value, original: { id } }) => {
        return (
          <span>
            {id === activeOrganization.businessId ? <FiHome /> : <FiBriefcase />}
            <span>{value}</span>
          </span>
        );
      }
    },
    {
      accessor: "vatPayer",
      width: VAT_PAYER_CELL_WIDTH,
      Header: uiTexts.vatPayer,
      Cell: ({ value }) => (<b className={value ? CommonCss.positiveText : CommonCss.negativeText}>
        {value ? uiTexts.yes : uiTexts.no}
      </b>)
    },
    {
      width: ACTIONS_CELL_WIDTH,
      sortable: false,
      className: CommonCss.flexEnd,
      Header: uiTexts.actions,
      Cell: ({ original: { id } }) => {
        return (
          <ActionsButtons
            itemId={id}
            disabled={disabled || !!activeOrganization.appsumoValue}
            disabledDelete={userRestrictions.businessesDelete || id === activeOrganization.businessId}
            onDelete={onDeleteBusiness} />
        );
      }
    }
  ],
  [
    disabled,
    userRestrictions,
    activeOrganization,
    uiTexts,
    onDeleteBusiness
  ]);

  return (
    <Table
      className={className}
      columns={tableColumns}
      data={data}
      pageSize={data.length}
      getTrProps={getTrProps} />
  );
};

export default React.memo(BusinessesTable);
